
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "dashboard",
  components: {},
  data() {
    return {
      data: {},
      course:{},
      tableData: [],
    };
  },
  async created() {
    await this.getData();
    await this.getTrainee();
    await this.getAssessor();
    await this.getCourses();
  },
  methods: {
    async getData() {
      await ApiService.get("dashboard/dashboard")
        .then((response) => {
          this.data = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourses() {
      await ApiService.get("courses/info")
        .then((response) => {
          this.course = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainee() {
      await ApiService.get("dashboard/dashboard")
        .then((response) => {
          this.data = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssessor() {
      await ApiService.get("dashboard/dashboard")
        .then((response) => {
          console.log(response);
          this.data = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  props: {
    widgetClasses: String,
    widgetColor: String,
    strokeColor: String,
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");

    const color = getCSSVariableValue("--bs-" + props.widgetColor);

    const strokeColor = ref(props.strokeColor);

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: strokeColor.value,
          opacity: 0.5,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 0,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [strokeColor.value],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: borderColor,
            width: 1,
            dashArray: 3,
          },
        },
      },
      yaxis: {
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
        marker: {
          show: false,
        },
      },
      markers: {
        colors: [color],
        strokeColor: [strokeColor.value],
        strokeWidth: 3,
      },
    };

    const series = [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ];

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      series,
      chartOptions,
    };
  },
});
